import { type FC, lazy } from "react";
import { lazyRetry } from "common/utility/Utils";
import { type routeType } from "router/routes/types";

const InboxWrapper: FC | any = lazy(
  async () =>
    await lazyRetry(
      async () => await import("../wrappers/InboxWrapper/InboxWrapper")
    )
);

const inboxRoutes: routeType[] = [
  {
    path: "/inbox",
    element: <InboxWrapper />,
    // permissions: "GET_MICRODIMENSIONS",
    meta: {
      title: "Inbox",
      layout: "app",
      type: "page",
      private: true,
      company: true,
    },
  },
];

export default inboxRoutes;
