import { Fragment, RefObject, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import zenhire from "assets/icons/zenhire-ico.png";
import notificationIco from "assets/icons/notification-ico.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-green-down.svg";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import Avatar from "../Avatar/Avatar";
import Spinner from "../spinners/Spinner";
import { useOutsideClickHandler } from "../../../common/hooks/useOutsideClickHandler";
import { setScrollDirection } from "redux/global";
import { useAuthorization } from "../../../common/hooks/useAuthorization";
import { useLocation, useNavigate } from "react-router";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { ReactComponent as ArrowDownDropdown } from "assets/icons/arrow-down-3.svg";

const AdminNavbar = () => {
  const dispatch = useAppDispatch();
  const scrollDirection = useAppSelector(
    (state) => state.global.scrollDirection,
  );
  const location = useLocation();
  const userData = useAppSelector((state) => state.authentication.userData);
  const navigate = useNavigate();

  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);

  const { checkPermissions } = useAuthorization();

  const ref: RefObject<HTMLDivElement> = useRef(null);
  const closeDropdownHandler = () => {
    setIsProfileDropdownOpen(false);
  };

  function closeMenu() {
    setTimeout(() => {
      closeDropdownHandler();
    }, 50);
  }

  const handleClickOutside = (event?: any) => {
    closeMenu();
  };

  useOutsideClickHandler(ref, handleClickOutside);

  const displayStyle = () => {
    if (scrollDirection === "DOWN") {
      closeMenu();
      return "-mt-[46px] transition-all";
    } else {
      if (scrollDirection === "UP") {
        return "mt-0 transition-all";
      }
      setTimeout(() => {
        return "relative z-50 block";
      }, 102);
    }

    return "relative z-50 block bg-black";
  };

  useEffect(() => {
    displayStyle();
  }, [scrollDirection]);

  const handleTestRedirect = () => {
    if (checkPermissions("GET_TESTS_PUBLISHED")) {
      return "/test-library/published";
    } else if (checkPermissions("GET_TESTS_INCUBATION")) {
      return "/test-library/incubator";
    } else if (checkPermissions("GET_TESTS_DRAFT")) {
      return "/test-library/drafts";
    }
  };

  useEffect(() => {
    if (scrollDirection === "UP") {
      setTimeout(() => {
        dispatch(setScrollDirection(null));
      }, 100);
    }
  }, [scrollDirection]);

  const navLinks = [
    {
      label: "Dashboard",
      to: "/",
    },
    {
      label: "Test Library",
      functionRedirect: () => handleTestRedirect(),
      to: handleTestRedirect(),
      permissions: [
        "GET_TESTS",
        "GET_TESTS_PUBLISHED",
        "GET_TESTS_DRAFT",
        "GET_TESTS_INCUBATION",
      ],
    },
    {
      label: "Users",
      to: "/users",
      permissions: "GET_USERS",
    },
    {
      label: "Applicants",
      to: "/applicants",
      permissions: "GET_APPLICANTS",
    },
    {
      label: "Competencies",
      to: "/microdimensions",
      permissions: "GET_MICRODIMENSIONS",
    },
    {
      label: "Positions",
      activeLink: location?.pathname?.startsWith(`/positions`),
      to: "/positions",
      permissions: "GET_POSITIONS",
      children: !checkPermissions("VIEW_POSITION_TEMPLATES")
        ? []
        : [
            {
              label: "Positions List",
              functionOption: () => navigate(`/positions`),
              permissions: "GET_POSITIONS",
              checked: location?.pathname === `/positions`,
            },
            {
              label: "Templates List",
              functionOption: () => navigate(`/positions/templates`),
              permissions: "VIEW_POSITION_TEMPLATES",
              checked: location?.pathname === `/positions/templates`,
            },
          ],
    },

    {
      label: "Companies",
      to: "/companies",
      permissions: "GET_COMPANY",
    },
    {
      label: "Emails",
      to: "/system-emails",
      permissions: "SYSTEM_EMAIL_TEMPLATE_GET",
    },
    {
      label: "Inbox",
      to: "/inbox",
      permissions: "",
    },
  ];

  return (
    <>
      {/* floating hover div */}
      {scrollDirection === "DOWN" ? (
        <>
          <div
            className="fixed w-full h-[17px] bg-transparent z-[999] cursor-pointer"
            onMouseEnter={() => {
              scrollDirection === "DOWN" && dispatch(setScrollDirection("UP"));
            }}
          >
            <div className="text-green fixed z-[888] top-2 fade-in-top w-full flex items-center justify-center">
              <ArrowDown className={"w-[12px]"} />
            </div>
          </div>
        </>
      ) : null}
      <div
        className={`${displayStyle()} bg-black ${
          scrollDirection !== "DOWN" ? "" : ""
        }`}
        ref={ref}
        id="adminNavbarID"
      >
        <div className="flex items-center m-auto h-[46px] px-[20px]">
          <NavLink
            to={"/"}
            className="flex items-center cursor-pointer gap-2 mr-auto"
            onClick={() => {
              setIsProfileDropdownOpen(false);
            }}
          >
            <img
              src={zenhire}
              className="w-[30px] h-auto"
              alt={"zenhire-logo"}
            />
            <p className="text-white text-[17px]">ZenHire Admin</p>
          </NavLink>

          <nav className="text-dimmed-white text-[13px] font-[500] flex">
            {navLinks
              .filter((link) =>
                (link.permissions && checkPermissions(link.permissions)) ||
                !link.permissions
                  ? link
                  : null,
              )
              .map((link, index) =>
                link.children?.length ? (
                  <Fragment key={index}>
                    <CustomDropdown
                      customToggler={
                        <Link
                          to={link.to}
                          key={index}
                          className={`py-[10px] px-3.5 hover:!text-white cursor-pointer flex justify-center items-center gap-2 group ${
                            link.activeLink ? "border-b-[2px] text-white" : ""
                          }`}
                        >
                          {link.label}
                          <ArrowDownDropdown
                            className={`group-hover:[&_path]:fill-white mt-[1px] ${link.activeLink ? "[&_path]:fill-white" : "[&_path]:fill-dimmed-white"}`}
                          />
                        </Link>
                      }
                      functionOption={link.children.filter((link) =>
                        (link.permissions &&
                          checkPermissions(link.permissions)) ||
                        !link.permissions
                          ? link
                          : null,
                      )}
                      menuClassName={`!mt-[7px]`}
                      toggleOnClick={false}
                    />
                  </Fragment>
                ) : (
                  <NavLink
                    key={index}
                    to={
                      link.functionRedirect
                        ? link.functionRedirect()
                        : (link.to as any)
                    }
                    className={({ isActive }) =>
                      `py-[10px] px-3.5 hover:text-white ${
                        isActive ||
                        (location.pathname.includes("test-library") &&
                          link?.to?.includes("test-library"))
                          ? "text-white border-b-[2px]"
                          : ""
                      }`
                    }
                    end={!link.to}
                    onClick={() => {
                      setIsProfileDropdownOpen(false);
                    }}
                  >
                    {link.label}
                  </NavLink>
                ),
              )}
          </nav>

          <div className="relative flex items-center gap-5 ml-7">
            <img
              src={notificationIco}
              className="cursor-pointer select-none w-[25px] h-auto"
              alt="notificaton-ico"
            />
            {userData.loading || userData.processing || !userData.value ? (
              <Spinner classIcon={"w-[30px] h-[30px]"} />
            ) : (
              <>
                <div
                  className="cursor-pointer w-[30px] h-[30px]"
                  onClick={() => {
                    setIsProfileDropdownOpen((prevState) => !prevState);
                  }}
                >
                  <Avatar
                    content={`${userData.value.name} ${userData.value.surname}`}
                    avatar={userData.value.avatar}
                    size={30}
                  />
                </div>

                {isProfileDropdownOpen && (
                  <div
                    className="absolute top-10 right-0 z-50"
                    onMouseLeave={closeDropdownHandler}
                  >
                    <ProfileDropdown
                      img={userData.value.avatar}
                      closeDropdown={closeDropdownHandler}
                      contentName={`${userData.value.name} ${userData.value.surname}`}
                      role={userData.value.role.description}
                      fullName={`${userData.value.name} ${userData.value.middleName} ${userData.value.surname}`}
                      settingsLink={"/profile-settings/general"}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminNavbar;
