import { http } from "common/api/http";
import { refreshHttp } from "common/api/refresh-http";
import { removeKey, storeKey } from "common/api/token";
import { objectToFormData } from "common/utility/Utils";

export const refreshAccessToken = async () => {
  try {
    const rs: { data: { access_token: string } } =
      await refreshHttp.get("/auth/refresh");
    storeKey({ access_token: rs.data.access_token });
    return true;
  } catch (error) {
    removeKey("zen_access_token");
    removeKey("zen_refresh_token");
    window.location.href = "/login";
    return false;
  }
};

export const fetchMicrodimensionsOptions = async () => {
  return await http.get(`/microdimensions/filter`);
};

export const fetchTestTemplateOptions = async () => {
  return await http.get(`/tests/templates/filter`);
};

export const fetchCompaniesOptions = async () => {
  return await http.get(`/companies/filter`);
};

export const uploadFile = async ({
  path,
  file,
  overwrite = false,
}: {
  path: string;
  file: File;
  overwrite?: boolean;
}) => {
  return await http.post(
    `/system/file`,
    objectToFormData({
      path,
      file,
      overwrite,
    }),
    { headers: { "Content-Type": "multipart/form-data" } },
  );
};
export const deleteFile = async ({ path }: { path: string }) => {
  return await http.delete(
    path.includes("http")
      ? path
      : path.includes("/system/file/")
        ? path
        : `/system/file/${path}`,
  );
};
export const fetchAllTestsOptions = async () => {
  return await http.get(`/tests/filter?state=published,incubation`);
};
export const fetchAllPublishedTestsOptions = async (company?: string) => {
  return await http.get(
    `/tests/filter?state=published${company ? `&company=${company}` : ""}`,
  );
};
export const fetchAllCompaniesOptions = async () => {
  return await http.get(`/companies/filter`);
};
export const fetchAllCompaniesWithoutSuspended = async () => {
  return await http.get(`/companies/filter?state=ACTIVE,PENDING`);
};
export const fetchUserRoles = async (userId?: string) => {
  return await http.get(`/users/roles${userId ? `?userId=${userId}` : ""}`);
};
export const fetchAllPermissionsOptions = async () => {
  return await http.get(`/users/permissions`);
};

export const fetchAllActivePositions = async (company?: string) => {
  return await http.get(
    `/positions/filter?state=ACTIVE${company ? `&company=${company}` : ""}`,
  );
};

export const fetchAllPositions = async (company?: string) => {
  return await http.get(
    `/positions/filter${company ? `?company=${company}` : ""}`,
  );
};

export const fetchAllCompanyUsers = async (company?: string) => {
  return await http.get(`/users/filter${company ? `?company=${company}` : ""}`);
};
