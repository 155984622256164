// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getCompaniesInbox, getUsersInbox } from "./inbox.actions";
import { errorType } from "common/types/reducer.types";
import { threadsType } from "../inbox.types";

interface reducerType {
  inboxThreads: {
    value: threadsType | null;
    loading: boolean;
    error: errorType | any;
  };
}

const initialState: reducerType = {
  inboxThreads: {
    value: null,
    loading: false,
    error: null,
  },
};

export const inbox = createSlice({
  name: "inbox",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompaniesInbox.pending, (state) => {
        state.inboxThreads.loading = true;
      })
      .addCase(getCompaniesInbox.fulfilled, (state, { payload }) => {
        console.log(payload);

        state.inboxThreads.value = payload;
        state.inboxThreads.loading = false;
      })
      .addCase(getCompaniesInbox.rejected, (state, { payload }: any) => {
        state.inboxThreads.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.inboxThreads.loading = payload?.code === "ERR_CANCELED";
      })
      .addCase(getUsersInbox.pending, (state) => {
        state.inboxThreads.loading = true;
      })
      .addCase(getUsersInbox.fulfilled, (state, { payload }) => {
        state.inboxThreads.value = payload;
        state.inboxThreads.loading = false;
      })
      .addCase(getUsersInbox.rejected, (state, { payload }: any) => {
        state.inboxThreads.error =
          payload?.code === "ERR_CANCELED" ? null : payload;
        state.inboxThreads.loading = payload?.code === "ERR_CANCELED";
      });
  },
});

export const {} = inbox.actions;

export default inbox.reducer;
