import { Fragment, FunctionComponent } from "react";
import FilterAccordion from "./FilterAccordion";
import { FiltersSideBarType } from "../filters-side-bar.type";
import { NavLink } from "react-router-dom";
import { AdditionalFiltersInput } from "./AdditionalFiltersInput";
import { useLocation } from "react-router";
import { isObjEmpty } from "../../../../common/utility/Utils";
import AdditionalFiltersFollowing from "./AdditionalFiltersFollowing";

type typeProps = {
  additionalFilters: FiltersSideBarType[];
  modalPath?: boolean;
};
const AdditionalFiltersNew: FunctionComponent<typeProps> = (props) => {
  const { additionalFilters, modalPath } = props;
  const location = useLocation();

  return (
    <div className={"flex flex-col gap-4"}>
      {additionalFilters
        .filter((object: any) => !isObjEmpty(object))
        .map((item: FiltersSideBarType, index: number) => (
          <Fragment key={index}>
            <FilterAccordion
              propsHasFilters={item.hasFilters}
              key={index}
              title={item.header || ""}
              loading={item.loading}
              filter_ids={
                item?.additionalFilters?.length
                  ? item?.additionalFilters.map((item: any) => {
                      return item.filter_id;
                    })
                  : null
              }
              count={item.count}
              className={item.className}
            >
              {item.additionalFilters?.map(
                (filter: FiltersSideBarType, index: number) =>
                  filter.type === "link" ? (
                    filter.filtersData.map(
                      (
                        link: {
                          label: string;
                          path: string;
                          handleClick: () => void;
                          Icon?: any;
                          count?: number;
                        },
                        index: number,
                      ) => (
                        <NavLink
                          to={link.path}
                          key={index}
                          onClick={() =>
                            link?.handleClick &&
                            location.pathname !== link.path &&
                            link?.handleClick()
                          }
                          end={true}
                          className={({ isActive }) =>
                            `flex gap-1.5 justify-between text-sm font-[400] px-[6px] py-[5px] rounded-[10px] hover:bg-z-grey-100 ${isActive ? "bg-z-grey-100 text-main-color [&_path]:fill-main-color cursor-default" : "text-z-grey-550 [&_path]:fill-z-grey-550"}`
                          }
                        >
                          <div className={"flex gap-1.5 items-center"}>
                            {link.Icon ? (
                              <link.Icon className={" w-[20px]"} />
                            ) : null}
                            {link.label}
                          </div>
                          {(link.count || 0) >= 0 && !item.loading ? (
                            <span>{link.count}</span>
                          ) : null}
                        </NavLink>
                      ),
                    )
                  ) : filter.type === "following" ? (
                    <Fragment key={index}>
                      <AdditionalFiltersFollowing
                        loading={false}
                        totalFollowedByMe={filter.totalFollowedByMe || 0}
                        totalNotFollowed={filter.totalNotFollowed || 0}
                      />
                    </Fragment>
                  ) : filter.type === "custom" ? (
                    <Fragment key={index}>{filter.children}</Fragment>
                  ) : (
                    <Fragment key={index}>
                      <AdditionalFiltersInput
                        filter={filter}
                        modalPath={modalPath}
                      />
                    </Fragment>
                  ),
              )}
            </FilterAccordion>
            {index <
            Number(
              additionalFilters?.filter((object: any) => !isObjEmpty(object))
                ?.length || 0,
            ) -
              1 ? (
              <hr className="border-1 border-z-grey-200" />
            ) : null}
          </Fragment>
        ))}
    </div>
  );
};

export default AdditionalFiltersNew;
