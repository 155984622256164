import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCompany } from "./company.services";
import { http } from "../../../../common/api/http";
import { setToast } from "../../../../redux/alert";
import { RootState } from "../../../../redux/store";
import axios from "axios";
export const getCompanyOverview = createAsyncThunk<
  any,
  { id: string; roleAdmin?: boolean },
  { state: RootState }
>(
  "company/getCompanyOverview",
  async ({ id, roleAdmin }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      if (!roleAdmin) {
        return state?.authentication?.userData?.value?.company;
      } else {
        const response = await fetchCompany(id);
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  }
);

export const refreshCompanyOverview = createAsyncThunk(
  "company/refreshCompanyOverview",
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const response = await fetchCompany(id);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "CUSTOM",
        style: "MAIN_CONTENT",
      });
    }
  }
);

type companyDataType = {
  id?: string;
  name?: string;
  address?: string;
  emailAddress?: string;
  websiteUrl?: string;
  description?: string;
  ourVisionDescription?: string;
  ourMissionDescription?: string;
  benefits?: string;
  color?: string;
  headerColor?: string;
  enableHeaderColor?: boolean;
  linkColor?: string;
  enableHeaderLogo?: boolean;
  privacyPolicy?: string;
  logo?: string;
  headerLogo?: string;
  defaultCountryCallingCode?: string;
};
export const updateCompanyInfo = createAsyncThunk(
  "companySingle/updateCompanyInfoData",
  async (data: companyDataType, { dispatch, rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const response = await http.patch(`/companies/${data?.id}`, {
        ...data,
        emailAddress: undefined,
      });
      dispatch(
        setToast({
          message: "Successfully updated the company.",
          type: "success",
        })
      );

      return {
        ...response.data,
        // BE need to return this info on comapny info update action response, after that we should delete this
        emailAddress: state?.company?.companyOverview?.value?.emailAddress
          ? state?.company?.companyOverview?.value?.emailAddress
          : data?.emailAddress
            ? data.emailAddress
            : "",
      };
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const createCompanyEmailAddress = createAsyncThunk<
  any,
  { companyId: string; emailAddress: string },
  {
    state: RootState;
  }
>(
  "company/createCompanyEmailAddress",
  async ({ companyId, emailAddress }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `/email/companies/${companyId}/email-address`,
        {
          emailAddress: emailAddress,
        }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const updateCompanyVisualIdentity = createAsyncThunk(
  "companySingle/updateCompanyVisualIdentity",
  async (
    {
      data,
      logo,
      headerLogo,
      companyId,
    }: {
      data: companyDataType;
      logo?: File | null;
      headerLogo?: File | null;
      companyId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.patch(
        `/companies/${companyId}/visual-identity`,
        {
          data: JSON.stringify(data),
          ...(logo && {
            logo,
          }),
          ...(headerLogo && {
            headerLogo,
          }),
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      dispatch(
        setToast({
          message: "Successfully updated the company.",
          type: "success",
        })
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const initiateEmailAddress = createAsyncThunk(
  "company/initiateEmailAddress",
  async (
    {
      id,
      data,
    }: {
      id: string;
      data: {
        newEmail: string;
        address: string;
        state: string;
        city: string;
        country: string;
        zip: string;
      };
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.post(
        `/companies/${id}/verified-sender`,
        data
      );
      dispatch(
        setToast({
          message: "Successfully submited request",
          type: "success",
        })
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const resendEmailAddressChangeRequest = createAsyncThunk(
  "company/resendEmailAddressChangeRequest",
  async (
    {
      id,
    }: {
      id: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.post(
        `/companies/${id}/verified-sender/resend`
      );
      dispatch(
        setToast({
          message: "Successfully resended request",
          type: "success",
        })
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const handleCustomEmailAddressState = createAsyncThunk(
  "company/handleCustomEmailAddressState",
  async (
    {
      id,
      state,
    }: {
      id: string;
      state: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await http.patch(
        `/companies/${id}/verified-sender/${state ? "enable" : "disable"}`
      );
      dispatch(refreshCompanyOverview({ id: id }));

      dispatch(
        setToast({
          message: `Custom company email turned ${state ? "on" : "off"}`,
          type: "success",
        })
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const getLocation = createAsyncThunk(
  "company/getLocation",
  async ({ value }: { value: string }, { rejectWithValue }) => {
    try {
      // mifflintown
      let url = `https://api.radar.io/v1/search/autocomplete?query=${value}&limit=6`;

      let options = {
        headers: {
          Authorization: "prj_test_pk_edd1c8544f2c36a10b5d96225ab4b63bd47af8b9",
          Accept: "application/json",
        },
      };

      const response = await axios.get(
        // production link
        url,
        options
      );

      return response.data.addresses;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);
