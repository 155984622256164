import { ReactComponent as InfoIco } from "assets/icons/info-ico.svg";
import React, { Fragment, ReactNode } from "react";
import Tooltip from "../Tooltip/Tooltip";
import { useAuthorization } from "../../../common/hooks/useAuthorization";
import Button from "../Button/Button";

export type functionOptionType = {
  Icon?:
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >
    | string;
  iconClassName?: string;
  label?: string;
  functionOption: (value?: string) => void;
  disabled?: boolean;
  tooltipDisabled?: string;
  countNumber?: number;
  hide?: boolean;
  borderTop?: boolean;
  checked?: boolean;
  childrenIcon?: ReactNode;
  disabledAction?: () => void;
};

type buttonType = {
  text: string;
  type: "Primary" | "Secondary" | "Naked" | "Export";
  IconButton: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  className?: string;
  disabled?: boolean;
  processing?: boolean;
};
type propTypes = {
  functionOption?: functionOptionType[];
  IconDropdown?:
    | React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >
    | string;
  classNameContainer?: string;
  id?: string;
  processing?: boolean;
  menuClassName?: string;
  positionMenu?: string;
  scrollTop?: number;
  classNameIconContainer?: string;
  fillIcon?: string;
  permissions?: string[];
  noPermissionsText?: string;
  button?: buttonType;
  classNameIcon?: string;
  customToggler?: ReactNode;
  customContent?: ReactNode;
  toggleOnClick?: boolean;
  togglerDisabled?: boolean;
};
const CustomDropdown = (props: propTypes) => {
  const {
    functionOption,
    IconDropdown,
    classNameContainer,
    menuClassName,
    classNameIconContainer,
    id,
    fillIcon,
    permissions,
    noPermissionsText,
    button,
    classNameIcon,
    togglerDisabled,
    customToggler,
    customContent,
    toggleOnClick = true,
  } = props;

  const { allPermissionsCheck } = useAuthorization();
  const selectFunction = (action: functionOptionType) => {
    if (action.disabled) return;
    else
      action.functionOption(props?.id?.toString() ? props.id.toString() : "");
  };

  const handleCheckOption = (action?: any) => {
    if (action.disabled && !action.disabledAction) return;
    if (action?.disabledAction && action?.disabled) {
      action?.disabledAction();
    }
    selectFunction(action);
  };

  return permissions && !allPermissionsCheck(permissions) ? (
    <Tooltip
      id={id}
      toggler={
        button ? (
          <Button
            disabled={true}
            state={button?.processing ? "Loading" : ""}
            type={button?.type || "Primary"}
            FunctionIcon={button.IconButton}
            text={button.text}
            className={button?.className}
          />
        ) : IconDropdown ? (
          <IconDropdown
            className={`[&_path]:fill-[#BFBFBF] ${classNameIcon ? classNameIcon : ""}`}
          />
        ) : null
      }
      content={
        noPermissionsText ? noPermissionsText : "You do not have permission"
      }
      disabled={togglerDisabled}
      className={"mt-3"}
    />
  ) : (
    <Tooltip
      id={id}
      toggler={
        customToggler ? (
          customToggler
        ) : button ? (
          <Button
            disabled={button?.disabled}
            state={button?.processing ? "Loading" : ""}
            type={button?.type || "Primary"}
            FunctionIcon={button.IconButton}
            text={button.text}
            className={button?.className}
          />
        ) : IconDropdown ? (
          <div
            style={{
              cursor: props.processing ? "default" : "pointer",
            }}
            className={`w-[35px] h-[35px] flex justify-center items-center rounded-full cursor-pointer hover:bg-main-color-light group ${
              classNameIconContainer ? classNameIconContainer : ""
            } `}
            onClick={() => {
              // helper function
              !props.processing && document.body.click();
            }}
          >
            <IconDropdown
              className={`${
                classNameContainer ? classNameContainer : ""
              } ${props.processing ? "[&_path]:fill-[#BFBFBF]" : fillIcon ? fillIcon : "[&_path]:fill-main-color"}`}
            />
          </div>
        ) : null
      }
      content={
        <div className={"w-max"}>
          {customContent
            ? customContent
            : functionOption
              ? functionOption
                  .filter((action) => !action.hide)
                  .map((action: functionOptionType, index: number) => (
                    <Fragment key={index}>
                      {action.borderTop ? (
                        <hr className="mt-3 mb-1 border-1 border-[#D1D1D1]" />
                      ) : null}
                      <div
                        onClick={() => handleCheckOption(action)}
                        className={`mt-1.5 first:mt-0 flex justify-between px-0 py-[2px] rounded-[5px] text-white items-center mx-2 h-[32px]
                               gap-2 cursor-pointer ${
                                 action.checked
                                   ? "bg-[#f4f4f438]"
                                   : action.disabled
                                     ? `bg-dark-gray ${action.disabledAction ? "!cursor-pointer" : "select-none !cursor-default"}`
                                     : "hover:bg-[#f4f4f438]"
                               }`}
                      >
                        <span
                          className={`flex items-center gap-2 pl-1 pr-2 text-sm`}
                        >
                          {action?.childrenIcon ? (
                            action.childrenIcon
                          ) : action.Icon ? (
                            <action.Icon
                              className={`w-[25px] ${
                                action.disabled
                                  ? "[&_path]:fill-light-gray"
                                  : ""
                              } ${action.iconClassName || ""}`}
                            />
                          ) : (
                            ""
                          )}
                          {action.label}
                        </span>
                        {action.disabled || action.countNumber ? (
                          <div
                            className={"flex justify-center items-center gap-2"}
                          >
                            {action.countNumber ? (
                              <>({action.countNumber})</>
                            ) : null}
                            {action.disabled && action.tooltipDisabled ? (
                              <Tooltip
                                toggler={
                                  <InfoIco className="[&_path]:fill-white" />
                                }
                                content={
                                  <p className="font-light text-white text-sm show-animation">
                                    {action.tooltipDisabled}
                                  </p>
                                }
                              ></Tooltip>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : null}
                      </div>
                    </Fragment>
                  ))
              : null}
        </div>
      }
      className={`${menuClassName ? menuClassName : "!mt-2 !py-3 my-0 px-0"} !py-2 !px-0`}
      toggleOnClick={toggleOnClick}
      disabled={togglerDisabled}
    />
  );
};
export default CustomDropdown;
