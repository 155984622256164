import {
  Fragment,
  FunctionComponent,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down-2.svg";
import Spinner from "../../spinners/Spinner";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxTypes";
import { useLocation } from "react-router";
import { isObjEmpty } from "../../../../common/utility/Utils";
import { setGlobalFilters } from "../../../../redux/globalFilters";

type typeProps = {
  title: string;
  children: ReactNode;
  loading?: boolean;
  filter_ids?: string[] | null;
  className?: string;
  propsHasFilters?: {
    status: boolean;
    clearFilters?: () => void;
  };
  count?: number | null;
};
const FilterAccordion: FunctionComponent<typeProps> = (props) => {
  const {
    title,
    children,
    loading,
    filter_ids,
    className,
    propsHasFilters,
    count,
  } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(true);
  const globalFilters: any = useAppSelector(
    (state) => state.globalFilters[location.pathname],
  );
  const hasFilters = useMemo(() => {
    if (!filter_ids?.length || !globalFilters?.filters) return false;

    return filter_ids.some(
      (id) =>
        id in globalFilters.filters &&
        (globalFilters.filters[id]?.length ||
          !isObjEmpty(globalFilters.filters[id])),
    );
  }, [globalFilters, filter_ids]);
  const handleResetFilters = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!filter_ids?.length) return;
    const filtersArray: any = globalFilters?.filters || {};
    const newInput = { ...filtersArray };
    filter_ids.map((item: string) => {
      return delete newInput[item];
    });
    dispatch(
      setGlobalFilters({
        location: location.pathname,
        filters: newInput,
        page: 1,
      }),
    );
  };

  const handleCustomResetFilters = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!propsHasFilters?.clearFilters) return;
    propsHasFilters.clearFilters();
  };
  return (
    <div className={"flex flex-col"}>
      <div
        onClick={(e) => setOpen(!open)}
        className={`flex items-center justify-between gap-2 cursor-pointer`}
      >
        {title ? (
          <Fragment>
            <div
              className={
                "flex items-center gap-2 text-sm font-[600] text-z-grey-600"
              }
            >
              {title}
              {count ? (
                <span
                  className={
                    "text-z-grey-550 text-sm font-[400] px-[6px] py-[2px] rounded-[10px] bg-z-grey-100 w-fit text-center"
                  }
                >
                  {count}
                </span>
              ) : null}
            </div>
            <div className={`flex gap-1 items-center`}>
              {(
                typeof propsHasFilters?.status === "boolean"
                  ? propsHasFilters?.status
                  : hasFilters
              ) ? (
                <span
                  className={
                    "text-xs text-main-color font-[600] cursor-pointer"
                  }
                  onClick={(e) =>
                    propsHasFilters?.clearFilters
                      ? handleCustomResetFilters(e)
                      : handleResetFilters(e)
                  }
                >
                  Reset
                </span>
              ) : null}
              <div
                className={
                  "hover:bg-[#F3F3F3] rounded-full w-[26px] h-[26px] flex items-center justify-between"
                }
              >
                {loading ? (
                  <Spinner classIcon={"w-[20px]"} />
                ) : (
                  <ArrowDown
                    className={`cursor-pointer w-[26px] transition-all duration-200 m-auto ${
                      open ? "rotate-180" : ""
                    }`}
                  />
                )}
              </div>
            </div>
          </Fragment>
        ) : null}
      </div>
      <div className={`flex flex-col gap-3 ${open ? "" : "hide-list"}`}>
        <div
          className={`mt-3 ${className ? className : ""} flex flex-col gap-[3px] ${
            open ? "expand-list transition-all" : "hide-list"
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default FilterAccordion;
