import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "../../../../common/api/http";
import { setToast } from "../../../../redux/alert";
import { RootState } from "../../../../redux/store";
import axios from "axios";

interface getThreadsParams {
  page?: number;
  read?: string;
  currentLocation?: string;
  companyId?: string;
  paramsArray?: any;
  signal?: any;
}

export const getCompaniesInbox = createAsyncThunk<
  any,
  getThreadsParams,
  { state: RootState }
>(
  "company/getCompaniesInbox",
  async (
    { page, read, companyId, paramsArray, signal },
    { rejectWithValue, getState }
  ) => {
    try {
      const params = {
        limit: 15,
        page: page ? page : 1,
        ...(read && {
          read: read,
        }),
        ...(paramsArray?.filters?.type && {
          automationType: paramsArray?.filters.type,
        }),
        ...(companyId && {
          company: companyId,
        }),
      };
      const response = await http.get("/email/companies/inbox", {
        params,
        signal,
      });

      const values = getState().inbox.inboxThreads.value;
      const newValues =
        values && response.data?.page && response.data.page > 1
          ? {
              ...response.data,
              data: [...values.data, ...response.data.data],
            }
          : response.data;
      return newValues;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);

export const getUsersInbox = createAsyncThunk<
  any,
  getThreadsParams,
  { state: RootState }
>(
  "company/getUsersInbox",
  async (
    { page, read, companyId, paramsArray, signal },
    { rejectWithValue, getState }
  ) => {
    try {
      const params = {
        limit: 15,
        page: page ? page : 1,
        ...(read && {
          read: read,
        }),
        ...(paramsArray?.filters?.type && {
          automationType: paramsArray?.filters.type,
        }),
        ...(companyId && {
          company: companyId,
        }),
      };

      const response = await http.get("/email/users/inbox", {
        params,
        signal,
      });

      const values = getState().inbox.inboxThreads.value;
      const newValues =
        values && response.data?.page && response.data.page > 1
          ? {
              ...response.data,
              data: [...values.data, ...response.data.data],
            }
          : response.data;
      return newValues;
    } catch (error: any) {
      return rejectWithValue({
        ...error.data,
        code: error.code,
        method: error.config.method,
        type: "ACTION",
        style: "TOAST",
      });
    }
  }
);
