import { FunctionComponent, useMemo } from "react";
import { FiltersSideBarType } from "../filters-side-bar.type";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../redux/reduxTypes";
import { setGlobalFilters } from "../../../../redux/globalFilters";
import MultipleFiltersSelect from "./MultipleFiltersSelect";
import SingleSelect from "../../Inputs/SingleSelect/SingleSelect";
import SingleFiltersSelect from "./SingleFiltersSelect";
import DatepickerInput from "../../Inputs/DatepickerInput/DatepickerInput";

type typeProps = {
  filter: FiltersSideBarType;
  modalPath?: boolean;
};

type singleFilterType = {
  label: string;
  Icon?: any;
  filter_id: string;
  value: string;
  default?: boolean;
  count?: number;
  disabled?: boolean;
  disableUnselect?: boolean;
};
export const AdditionalFiltersInput: FunctionComponent<typeProps> = (props) => {
  const { filter, modalPath } = props;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const globalFilters = useAppSelector(
    (state) =>
      state.globalFilters[
        modalPath ? `${location.pathname}?modal=true` : location.pathname
      ],
  );

  const handleApplyFilters = (filtersArray: any) => {
    dispatch(
      setGlobalFilters({
        location: modalPath
          ? `${location.pathname}?modal=true`
          : location.pathname,
        filters: filtersArray,
        page: 1,
      }),
    );
  };

  const handleClick = (item: singleFilterType) => {
    if (item.disabled) return;
    // Get the current filter id or default to an empty string
    const filterId = filter.filter_id || "";
    // Check if the item.value is currently in the global filters
    const filtersArray: any = globalFilters?.filters || {};
    if (filtersArray[filterId] === item.value) {
      if (filter.disableUnselect) return;
      // Remove the item.value from the input state
      const newInput = { ...filtersArray };
      delete newInput[filterId];

      handleApplyFilters(newInput);
    } else {
      // Add the item.value to the input state
      handleApplyFilters({ ...filtersArray, [filterId]: item.value });
    }
  };

  const checkCheckedValue = (item: singleFilterType) => {
    const filtersArray: any = globalFilters?.filters || {};
    return filtersArray[filter.filter_id || ""] === item.value;
  };

  const filtersArray = useMemo(() => {
    const filtersArray: any = globalFilters?.filters || {};
    return filtersArray[filter.filter_id || ""];
  }, [globalFilters?.filters]);

  const checkEmptyValue = useMemo(() => {
    if (!filter.checkEmptyField) return;
    const filtersArray: any = globalFilters?.filters || {};
    return !filtersArray[filter.checkEmptyField || ""];
  }, [globalFilters?.filters]);
  return filter.type === "single" ? (
    filter?.filtersData?.map((item: singleFilterType, index: number) => (
      <div
        key={index}
        className={`cursor-pointer text-sm flex justify-between items-center font-[400] px-[6px] py-[5px] rounded-[10px] hover:bg-z-grey-100 ${checkCheckedValue(item) || (item.default && !filtersArray) ? "bg-z-grey-100 text-main-color [&_path]:fill-main-color" : "[&_path]:fill-z-grey-550 text-z-grey-550"}`}
        onClick={() => handleClick(item)}
      >
        <div className={"flex flex-wrap gap-1.5 break-all items-center"}>
          {item?.Icon ? <item.Icon className={"w-[20px]"} /> : null}
          {item.label}
        </div>
        {(item?.count || 0) >= 0 ? <span>{item.count}</span> : null}
      </div>
    ))
  ) : filter.type === "multiple" ? (
    <MultipleFiltersSelect
      selectedData={
        globalFilters?.filters
          ? (globalFilters?.filters as any)[filter.filter_id || ""]
          : []
      }
      data={filter.filtersData}
      onChange={(value) =>
        handleApplyFilters({
          ...globalFilters?.filters,
          [filter.filter_id || ""]: value,
        })
      }
      placeholder={filter.placeholder}
      disabled={!filter?.filtersData?.length}
    />
  ) : filter.type === "singleSelect" ? (
    !!(filter.checkEmptyField && checkEmptyValue) ? null : (
      <SingleSelect
        data={
          filter.filtersData ? filter.filtersData : [{ value: "", label: "" }]
        }
        selectedValue={
          globalFilters?.filters
            ? (globalFilters?.filters as any)[filter.filter_id || ""]
            : {}
        }
        menuPosition={"fixed"}
        onChange={(value) =>
          handleApplyFilters({
            ...globalFilters?.filters,
            [filter.filter_id || ""]: value,
          })
        }
        disabled={
          !!(filter.checkEmptyField && checkEmptyValue) ||
          !filter?.filtersData?.length
        }
        filtersSelect
        placeholder={filter?.placeholder}
      />
    )
  ) : filter.type === "singleSearch" ? (
    <SingleFiltersSelect
      selectedData={
        globalFilters?.filters
          ? (globalFilters?.filters as any)[filter.filter_id || ""]
          : []
      }
      data={filter.filtersData}
      onChange={(value) =>
        handleApplyFilters({
          ...globalFilters?.filters,
          [filter.filter_id || ""]: value,
        })
      }
      placeholder={filter.placeholder}
      disabled={!filter?.filtersData?.length}
    />
  ) : filter.type === "date" ? (
    <DatepickerInput
      placeholder={filter.placeholder}
      date={
        globalFilters?.filters &&
        (globalFilters?.filters as any)[filter?.filter_id || ""]
          ? new Date((globalFilters?.filters as any)[filter?.filter_id || ""])
          : null
      }
      onChange={(value) => {
        handleApplyFilters({
          ...globalFilters?.filters,
          [filter?.filter_id || ""]: value,
        });
      }}
      type={"filter"}
      wrapperDatePickerClassName={"w-full text-sm"}
      iconClassName={"w-[15px]"}
      inputClassName={"h-[29px]"}
    />
  ) : null;
};
