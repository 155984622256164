import { useEffect, useState } from "react";
import {
  closedQuestionInput,
  openQuestionInput,
  preintrviewQuestionInput,
  defaultQuestionInput,
  initClosedConfig,
  typingSpeedInput,
  statementScaleInput,
} from "../testSingle.constants";
import { useAppDispatch, useAppSelector } from "redux/reduxTypes";
import { testQuestionType } from "../testSingle.types";
import {
  checkIsTextHaveNodes,
  isObjEmpty,
  isStringEmpty,
} from "common/utility/Utils";
import {
  createTestQuestion,
  updateTestQuestion,
} from "../reducer/testSingle.actions";
import { emptyTextEditorState } from "views/components/TextEditor/sampleText";
import { checkTextEditorContent } from "views/components/TextEditor/TextEditor";
import { setToast } from "redux/alert";
import useCheckEqualObjects from "../../../../common/hooks/useCheckEqualObjects";
import { findQuestionTextEditorField } from "common/helpers/functions.helpers";
import { useAuthorization } from "common/hooks/useAuthorization";

export function getEmptyInput(type: string) {
  switch (type) {
    case "OPEN_QUESTIONS":
      return openQuestionInput;
    case "CLOSED_QUESTIONS":
      return closedQuestionInput;
    case "PRE_INTERVIEW": // preinterview
      return preintrviewQuestionInput;
    case "TYPING_SPEED": // typing speed
      return typingSpeedInput;
    case "STATEMENT_SCALE":
      return statementScaleInput;
    case "QUALIFYING_QUESTIONS":
      return closedQuestionInput;
    default:
      return defaultQuestionInput;
  }
}

function renderSpecificConfig(type: string, questionInput: testQuestionType) {
  switch (type) {
    case "OPEN_QUESTIONS":
      return {
        openConfig: {
          charLimit: questionInput.openConfig?.charLimit || 0,
        },
      };
    case "CLOSED_QUESTIONS":
      return {
        closedConfig: questionInput?.closedConfig
          ? {
              type: questionInput.closedConfig?.type || "text",
              hasMultipleAnswers:
                questionInput.closedConfig?.hasMultipleAnswers,
              displayStyle:
                questionInput.closedConfig?.displayStyle || "vertical",
              negativePoints: questionInput.closedConfig?.negativePoints || 0,
              options:
                questionInput.closedConfig?.options.map((o) => {
                  return {
                    ...o,
                    defaultText:
                      questionInput.closedConfig?.type === "text"
                        ? o.text
                        : o.imageUrl || "",
                  };
                }) || initClosedConfig.options,
            }
          : initClosedConfig,
      };

    case "PRE_INTERVIEW":
      return {
        preinterviewConfig: {
          preparationTime:
            questionInput.preinterviewConfig?.preparationTime || 0,
        },
      };

    case "STATEMENT_SCALE":
      return {
        statementScaleTestConfig: {
          isScaleReversed: false,
        },
      };

    case "QUALIFYING_QUESTIONS":
      return {
        closedConfig: questionInput?.closedConfig
          ? {
              type: questionInput.closedConfig?.type || "text",
              hasMultipleAnswers:
                questionInput.closedConfig?.hasMultipleAnswers,
              displayStyle:
                questionInput.closedConfig?.displayStyle || "vertical",
              negativePoints: 0,
              options:
                questionInput.closedConfig?.options.map((o) => {
                  return {
                    ...o,
                    defaultText:
                      questionInput.closedConfig?.type === "text"
                        ? o.text
                        : o.imageUrl || "",
                  };
                }) || initClosedConfig.options,
            }
          : initClosedConfig,
      };
    default:
      return {};
  }
}

function validateQuestionInput(
  type: string,
  questionInput: testQuestionType,
  timerType?: string
) {
  switch (type) {
    case "OPEN_QUESTIONS":
      var checkQuestionInput = checkTextEditorContent(questionInput.question);
      return (
        !isStringEmpty(questionInput.question) &&
        questionInput.question !== emptyTextEditorState &&
        (checkQuestionInput.hasText || checkQuestionInput.hasImage) &&
        questionInput.importance &&
        questionInput.microDims.length &&
        questionInput.openConfig?.charLimit &&
        (timerType === "per_test" ||
          (timerType === "per_question" && questionInput.timeLimit))
      );
    case "CLOSED_QUESTIONS":
      var checkQuestionInput = checkTextEditorContent(questionInput.question);

      return (
        !isStringEmpty(questionInput.question) &&
        questionInput.question !== emptyTextEditorState &&
        (checkQuestionInput.hasText || checkQuestionInput.hasImage) &&
        questionInput.microDims.length &&
        questionInput.importance &&
        (timerType === "per_test" ||
          (timerType === "per_question" && questionInput.timeLimit)) &&
        questionInput.closedConfig?.options.length &&
        !questionInput.closedConfig.options.some((v) => {
          let checkAnswersImage = v.imageUrl?.length;
          let checkAnswersInput = checkTextEditorContent(v.text);

          return questionInput?.closedConfig?.type === "text"
            ? isStringEmpty(v.text) ||
                v.text === emptyTextEditorState ||
                (!checkAnswersInput.hasImage && !checkAnswersInput.hasText)
            : !checkAnswersImage;
        }) &&
        (questionInput?.closedConfig?.hasMultipleAnswers
          ? questionInput.closedConfig.options.filter((v) => v.isCorrect)
              .length >= 2
          : questionInput.closedConfig.options.some((v) => v.isCorrect))
      );
    case "PRE_INTERVIEW": // preinterview
      var checkQuestionInput = checkTextEditorContent(questionInput.question);

      return (
        !isStringEmpty(questionInput.question) &&
        (questionInput.isTextEditor
          ? questionInput.question !== emptyTextEditorState &&
            (checkQuestionInput.hasText || checkQuestionInput.hasImage)
          : true) &&
        questionInput.importance &&
        questionInput.timeLimit
      );
    case "TYPING_SPEED": // typing speed
      return !isStringEmpty(questionInput.question) && questionInput.timeLimit;
    case "STATEMENT_SCALE":
      var checkQuestionInput = checkTextEditorContent(questionInput.question);

      return (
        !isStringEmpty(questionInput.question) &&
        questionInput.question !== emptyTextEditorState &&
        (checkQuestionInput.hasText || checkQuestionInput.hasImage) &&
        questionInput.microDims.length &&
        timerType === "per_test"
      );
    case "QUALIFYING_QUESTIONS":
      var checkQuestionInput = checkTextEditorContent(questionInput.question);

      return (
        !isStringEmpty(questionInput.question) &&
        questionInput.question !== emptyTextEditorState &&
        (checkQuestionInput.hasText || checkQuestionInput.hasImage) &&
        questionInput.closedConfig?.options.length &&
        !questionInput.closedConfig.options.some((v) => {
          let checkAnswersImage = v.imageUrl?.length;
          let checkAnswersInput = checkTextEditorContent(v.text);

          return questionInput?.closedConfig?.type === "text"
            ? isStringEmpty(v.text) ||
                v.text === emptyTextEditorState ||
                (!checkAnswersInput.hasImage && !checkAnswersInput.hasText)
            : !checkAnswersImage;
        }) &&
        (questionInput?.closedConfig?.hasMultipleAnswers
          ? questionInput.closedConfig.options.filter((v) => v.isCorrect)
              .length >= 2
          : questionInput.closedConfig.options.some((v) => v.isCorrect))
      );
    default:
      return false;
  }
}

const useQuestionEditorSlice = ({ type }: { type: string }) => {
  const dispatch = useAppDispatch();
  const { checkPermissions } = useAuthorization();

  const [loading, setLoading] = useState(true);

  const testOverview = useAppSelector(
    (state) => state.testSingle.testSingleOverview
  );
  const testQuestion = useAppSelector(
    (state) => state.testSingle.testSingleQuestion
  );

  const [questionInput, setQuestionInput] = useState(renderEmptyInput());
  const [initialQuestionInput, setInitialQuestionInput] =
    useState(renderEmptyInput());
  const [saveError, setSaveError] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const checkEqual = useCheckEqualObjects(initialQuestionInput, questionInput);

  useEffect(() => {
    setHasChanges(checkEqual);
  }, [questionInput, checkEqual]);

  function handleSaveQuestion(redirect?: (() => void) | null) {
    if (
      testOverview.value &&
      validateQuestionInput(type, questionInput, testOverview.value?.timerType)
    ) {
      if (testQuestion.state === "edit") {
        dispatch(
          updateTestQuestion({
            ...questionInput,
            microDimIds: questionInput.microDims.map((m) => m._id),
            ...(checkPermissions("CONNECT_MICRODIMS_TO_AI") && {
              willBeScoredByAiMicroDimIds: questionInput.microDims
                .map((m) => {
                  if (m?.willBeScoredByAi) {
                    return {
                      microDimId: m._id,
                      customSkillsContext: m.customSkillsContext,
                    };
                  } else {
                    return { microDimId: "", customSkillsContext: "" };
                  }
                })
                ?.filter((mAi) => mAi.microDimId),
            }),
            testId: testOverview.value._id,
            ...(type === "QUALIFYING_QUESTIONS" && {
              importance: 1,
            }),
            ...(type === "PRE_INTERVIEW" && {
              question: questionInput.question,
              tts: {
                text: questionInput?.question,
                enabled:
                  questionInput?.tts?.enabled && !questionInput?.isTextEditor
                    ? questionInput?.tts?.enabled
                    : false,
              },
              isTextEditor: questionInput?.isTextEditor,
              customSkillsContext: questionInput?.customSkillsContext,
            }),
          })
        )
          .unwrap()
          .then(() => {
            redirect && redirect();
          })
          .catch((error) => console.log(error));
      } else {
        dispatch(
          createTestQuestion({
            ...questionInput,
            microDimIds: questionInput.microDims.map((m) => m._id),
            willBeScoredByAiMicroDimIds: questionInput.microDims
              .map((m) => {
                if (m?.willBeScoredByAi) {
                  return {
                    microDimId: m._id,
                    customSkillsContext: m.customSkillsContext,
                  };
                } else {
                  return { microDimId: "", customSkillsContext: "" };
                }
              })
              ?.filter((mAi) => mAi.microDimId),
            testId: testOverview.value._id,
            ...(type === "QUALIFYING_QUESTIONS" && {
              importance: 1,
            }),
            ...(type === "PRE_INTERVIEW" && {
              question: questionInput.question,
              tts: {
                text: questionInput?.question,
                enabled: questionInput?.tts?.enabled
                  ? questionInput?.tts?.enabled
                  : false,
              },
              isTextEditor: questionInput?.isTextEditor,
              customSkillsContext: questionInput?.customSkillsContext,
            }),
          })
        )
          .unwrap()
          .then(() => {
            redirect && redirect();
          })
          .catch((error) => console.log(error));
      }
    } else {
      dispatch(
        setToast({
          type: "error",
          message:
            "In order to save the question, you need to fill all required fields.",
        })
      );

      setSaveError(true);
    }
  }

  function updateQuestionInput(input: testQuestionType) {
    setQuestionInput({
      ...questionInput,
      ...input,
    });
  }

  function renderExistingInput(question: testQuestionType) {
    var { openConfig, closedConfig, preinterviewConfig } = renderSpecificConfig(
      type,
      question
    );
    return {
      ...question,
      _id: question._id,
      question: question.question,
      importance: question.importance,
      timeLimit: question.timeLimit,
      isInDemo: question.isInDemo,
      microDims: question?.microDims?.length
        ? question.microDims
        : question?.microDimIds?.length
          ? question?.microDimIds
          : [],
      openConfig,
      closedConfig,
      preinterviewConfig,
      tts: {
        text: question.question,
        enabled: question.tts?.enabled ? question.tts?.enabled : false,
      },
      isTextEditor: question?.isTextEditor,
      customSkillsContext: question?.customSkillsContext,
    };
  }

  function renderEmptyInput() {
    var newInput = getEmptyInput(type);

    return {
      ...newInput,
      microDims: testOverview.value?.microDims?.length
        ? testOverview.value.microDims.filter((v: any) => v.required)
        : [],
    };
  }

  function handleInitInput() {
    setLoading(true);
    if (
      (testQuestion.state === "duplicate" || testQuestion.state === "edit") &&
      testQuestion.value?._id
    ) {
      setQuestionInput(renderExistingInput(testQuestion.value));
      setInitialQuestionInput(renderExistingInput(testQuestion.value));
    } else {
      setTimeout(() => {
        setQuestionInput(renderEmptyInput());
        setInitialQuestionInput(renderEmptyInput());
      }, 100);
    }
    setLoading(false);
    setSaveError(false);
  }

  function handleDiscardChanges() {
    setLoading(true);
    setTimeout(() => {
      handleInitInput();
    }, 100);
  }

  useEffect(() => {
    handleInitInput();
  }, [testQuestion.value, testQuestion.state]);

  useEffect(() => {
    if (!isObjEmpty(testQuestion.error)) {
      setSaveError(true);
    }
  }, [testQuestion.error]);

  return {
    loading,
    questionInput,
    hasChanges,
    saveError,
    handleDiscardChanges,
    updateQuestionInput,
    handleSaveQuestion,
  };
};

export default useQuestionEditorSlice;
