import {
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CreateTooltipPortal from "../CreateTooltipPortal/CreateTooltipPortal";
import { useOutsideClickHandler } from "common/hooks/useOutsideClickHandler";

type ToolTipTypes = {
  toggler: ReactNode;
  content: ReactNode;
  className?: string;
  disabled?: boolean;
  toggleOnClick?: boolean;
  id?: string;
  containerClassName?: string;
};

const Tooltip = ({
  toggler,
  content,
  className,
  disabled,
  toggleOnClick,
  id,
  containerClassName,
}: ToolTipTypes) => {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [rightEdgeGap, setRightEdgeGap] = useState(window.innerWidth);
  const [show, setShow] = useState(false);
  const [positionChangeDetected, setPositionChangeDetected] = useState(false);

  useOutsideClickHandler(elementRef, () => {
    setShow(false);
  });

  const position = useMemo(() => {
    if (elementRef.current) {
      let documentHeight = document.documentElement.clientHeight;
      let tooltipHeight = tooltipRef.current?.clientHeight || 0;
      const contentHeight = tooltipRef.current?.offsetHeight || 0;
      let elementPosition = elementRef.current.getBoundingClientRect();
      let tooltipPosition = {
        left: elementPosition.left,
        top:
          tooltipHeight + elementPosition.bottom > documentHeight
            ? elementPosition.top - tooltipHeight - 7
            : elementPosition.bottom - 7,
        height:
          contentHeight > elementPosition?.top &&
          tooltipHeight + elementPosition.bottom > documentHeight
            ? elementPosition?.top - 10
            : "",
      };

      setTimeout(() => {
        return tooltipPosition;
      }, 100);

      return tooltipPosition;
    }
    return null;
  }, [elementRef.current, tooltipRef.current, show, rightEdgeGap]);

  useLayoutEffect(() => {
    if (position) {
      setRightEdgeGap(window.innerWidth - position.left);
    }
  }, [position]);

  useEffect(() => {
    setPositionChangeDetected((prevState) => !prevState);
  }, [position?.top, rightEdgeGap, tooltipRef.current]);

  return (
    <>
      <div
        className={`max-w-full select-none ${containerClassName ? containerClassName : ""}`}
        ref={elementRef}
        onMouseEnter={() => !disabled && !toggleOnClick && setShow(true)}
        onMouseLeave={() => !disabled && !toggleOnClick && setShow(false)}
        onClick={(e) => {
          if (toggleOnClick && !disabled) {
            e.stopPropagation();
            show ? setShow(false) : setShow(true);
          }
        }}
      >
        {toggler ? toggler : ""}
        {position?.left && position?.top && content ? (
          <CreateTooltipPortal>
            <div
              id={id}
              ref={tooltipRef}
              className={`overflow-y-auto my-[5px] max-w-[400px] invisible text-white ${className} fixed z-50 bg-black flex flex-col gap-2 p-3 rounded-xl show-animation border border-[#DEDEDE] mt-2`}
              style={{
                top: position.top,
                left: position.left,
                visibility: show ? "visible" : "hidden",
                marginLeft: tooltipRef.current
                  ? rightEdgeGap < tooltipRef.current.clientWidth
                    ? -tooltipRef.current.clientWidth + rightEdgeGap - 8
                    : ""
                  : "",
                height: `${position?.height}px`,
              }}
              onClick={(e) => {
                toggleOnClick && show && setShow(false);
                e.stopPropagation();
              }}
            >
              {content}
              {/* Placeholder span for detecting tooltip position change */}
              <span className="fixed left-0 top-0 z-[-1] invisible">
                {positionChangeDetected ? "y" : "n"}
              </span>
            </div>
          </CreateTooltipPortal>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Tooltip;
